




















import {Component, Model, Prop, Vue, Watch} from "vue-property-decorator";
import { AttachmentHostType } from "@/api/appService";

@Component({
  name: "MultipleUploadImageUrls",
})
export default class MultipleUploadImage extends Vue {
  @Model("change", { type: String, default: "" }) readonly imagePaths!: string;

  @Prop({ required: true })
  hostType!: AttachmentHostType;

  @Prop({ required: false, default: 20 })
  limit?: number;

  private fileList: any[] = [];

  private imagePathArray: string[] = [];

  private dialogImageUrl = "";
  private dialogVisible = false;
  private hideUpload = false;

  @Watch('imagePaths')
  onImagePathsChanged(newVal: string) {
    this.fileList = [];
    this.imagePathArray = [];

    if (newVal && newVal !== "") {
      this.imagePathArray = newVal.split(",");
      this.imagePathArray.forEach((m) => {
        const fileName = m.split('/').pop() || '已上传文件';
        this.fileList.push({
          url: m,
          name: fileName
        });
      });
    }
  }
  async created() {
    debugger
    console.log("MultipleUploadImage created");
    this.fileList=[]
    this.imagePathArray=[]
    if (this.imagePaths && this.imagePaths !== "") {
      this.imagePathArray = this.imagePaths.split(",");
      this.imagePathArray.forEach((m) => {
        this.fileList.push({ url: m });
      });
    }
    this.handleChange(null, this.fileList);
  }
  beforeRemove(file: any) {
    return this.$confirm(`确定移除 ${file.name}？`);
  }
  async handleSuccess(res: any, file: any) {
    if (res.success) {
      this.imagePathArray.push(res.result.url);
      this.fileList.push({
        url: res.result.url,
        name: file.name // 添加文件名
      });
      this.$emit("change", this.imagePathArray.join(","));
    }
  }
  onPreview(file: any) {
    window.open(file.url);
  }

  get headers() {
    return {
      "Abp-OrganizationUnitId": (this as any).$store.getters["user/ouId"] || "",
      "Abp-TenantId": (this as any).$store.getters["app/tenantId"],
      Authorization: `Bearer ${(this as any).$store.getters["user/token"]}`,
      ".AspNetCore.Culture": "c=zh-Hans|uic=zh-Hans",
    };
  }

  get uploadParameter() {
    return { hostType: this.hostType };
  }

  private beforeUpload(file: any) {
    console.log(file);
    const isJPG = file.type === "image/jpeg";
    const isLt2M = file.size / 1024 / 1024 < 10;
    if (!isLt2M) {
      this.$message.error("上传图片大小不能超过 10MB!");
      return false;
    } else {
      return true;
    }
  }

  handleRemove(file: any) {
    console.log(file);
    let index = this.imagePathArray!.indexOf(file.url);
    this.imagePathArray!.splice(index, 1);
    this.fileList!.splice(index, 1);
    this.$emit("change", this.imagePathArray.join(","));
    this.handleChange(null, this.fileList);
  }

  handlePictureCardPreview(file: any) {
    this.dialogImageUrl = file.url;
    this.dialogVisible = true;
  }

  masterFileMax(files: any, fileList: any) {
    console.log(files, fileList);

    this.$message.warning(`请最多上传 ${this.limit} 张图片。`);
  }

  handleChange(files: any, fileList: any) {
    // this.hideUpload = fileList.length >= this.limit!;
  }
}
